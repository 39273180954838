import React, { ReactNode, useEffect } from "react";
import { Toaster } from "@/components/ui/sonner";
import { useTawkMessenger } from "@/providers/TawkMessengerProvider";
import { usePageData } from "@/hooks/usePageData";
import { toast } from "sonner";

const Global = ({ children }: { children: ReactNode }) => {
  const { auth, flash } = usePageData();
  const { isLoaded } = useTawkMessenger();

  useEffect(() => {
    if (auth && isLoaded) {
      window.Tawk_API?.setAttributes({
        name: auth.first_name + " " + auth.last_name,
        pilot_id: auth.pilot_id,
        email: auth.email,
        hash: auth.tawkHash,
      });
    }
  }, [isLoaded, auth]);

  useEffect(() => {
    if (flash.success) {
      toast.success(flash.success);
    }
    if (flash.error) {
      toast.error(flash.error);
    }
  }, [flash.success, flash.error]);

  return (
    <>
      {children}
      <Toaster />
    </>
  );
};

export default Global;
