import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { usePageData } from "@/hooks/usePageData";
import { Icon } from "@/components/Icon";
import { Link } from "@inertiajs/react";
import { ChevronsUpDown } from "lucide-react";

export function NavUser() {
  const { isMobile } = useSidebar();
  const { auth } = usePageData();

  const profileLinks = [
    {
      href: route("crew.show", auth.pilot_id),
      icon: "fa-user-pilot",
      label: "My profile",
    },
    {
      href: route("crew.account.loa.index"),
      icon: "fa-suitcase-rolling",
      label: "Pause account (LOA)",
    },
    {
      href: route("crew.account.loa.index"),
      icon: "fa-book-bookmark",
      label: "Logbook",
    },
    {
      href: route("crew.account.loa.index"),
      icon: "fa-money-from-bracket",
      label: "Transactions",
    },
    {
      href: route("crew.settings.index"),
      icon: "fa-cog",
      label: "Settings",
    },
  ];

  return (
    <SidebarMenu className={"p-2"}>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarFallback className="rounded-lg">
                  {auth.first_name.slice(0, 1)}
                  {auth.last_name.slice(0, 1)}
                </AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {auth.first_name + " " + auth.last_name}
                </span>
                <span className="truncate text-xs">QFA{auth.pilot_id}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "top"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarFallback className="rounded-lg">
                    {auth.first_name.slice(0, 1)}
                    {auth.last_name.slice(0, 1)}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {auth.first_name + " " + auth.last_name}
                  </span>
                  <span className="truncate text-xs">{auth.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            {auth.staff && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem className="gap-2" asChild>
                    <Link href={route("qvac.dashboard")}>
                      <Icon icon={"fa-hammer"} iconStyle="SOLID" />
                      Administration
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              {profileLinks.map((link) => (
                <DropdownMenuItem
                  className="gap-2"
                  asChild
                  key={link.label + link.href}
                >
                  <Link href={link.href}>
                    <Icon icon={link.icon} iconStyle="SOLID" />
                    {link.label}
                  </Link>
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="gap-2 text-red-500 w-full" asChild>
              <Link
                as={"button"}
                href={route("inertia.logout")}
                method={"post"}
              >
                <Icon icon={"fa-right-from-bracket"} iconStyle="SOLID" />
                Logout
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
