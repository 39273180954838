import { usePage } from "@inertiajs/react";

import { TBreadcrumb } from "@/types/breadcrumb-types";
import { User } from "@/types";

export type NavItem = {
  id?: string;
  parentId?: string | null;
  label: string;
  type:
    | "workspace"
    | "link"
    | "header"
    | "logout"
    | "dropdown"
    | "livechat"
    | "divider";
  class?: string | null;
  icon?: string | null;
  link?: string | null;
  new_window?: boolean;
  visible?: boolean;
  submenu?: NavItem[] | null;
  badge?: number | null;
};

export type Auth = User;

export type PageData = {
  breadcrumbs: TBreadcrumb[];
  environment: string;
  version: string;
  errors: Record<string, unknown>;
  messages: Record<string, unknown>;
  company: {
    companyName: string;
  };
  flash: {
    success: string | null;
    error: string | null;
  };
  auth: Auth;
  platform: {
    version: string;
    primaryAssetLevel: string;
  };
};

export const usePageData = <T>() => {
  return usePage().props as PageData & T;
};
